@tailwind base;
@tailwind components;
@tailwind utilities;

/* .cursor-video {
  cursor: var(--video-cursor), pointer !important;
} */

.flowGradient:hover {
  background: radial-gradient(
      210.77% 113.26% at 5.95% 7.14%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(
      136deg,
      rgba(255, 255, 255, 0.23) 0%,
      rgba(255, 255, 255, 0) 97.5%
    ),
    var(--Flow-Blue, #34b2ff);
}
.flowGradientWhiteMode:not(.dark *) {
  background: radial-gradient(
      210.77% 113.26% at 5.95% 7.14%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(
      136deg,
      rgba(255, 255, 255, 0.23) 0%,
      rgba(255, 255, 255, 0) 97.5%
    ),
    var(--Flow-Blue, #34b2ff);
}

.flowContactGradient {
  background: radial-gradient(
      210.77% 113.26% at 5.95% 7.14%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(
      136deg,
      rgba(255, 255, 255, 0.23) 0%,
      rgba(255, 255, 255, 0) 97.5%
    ),
    var(--Flow-Blue, #34b2ff);
}

.flowGradientText {
  background: radial-gradient(
      210.77% 113.26% at 5.95% 7.14%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(
      136deg,
      rgba(255, 255, 255, 0.23) 0%,
      rgba(255, 255, 255, 0) 97.5%
    ),
    var(--Flow-Blue, #34b2ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.dashboardShadow {
  box-shadow: 11px 14px 43.8px 0px rgba(0, 0, 0, 0.55);
}
.dashboardShadow:not(.dark *) {
  box-shadow: 0px 1px 19px 0px rgba(210, 210, 210, 0.55);
}

.flowPill {
  background: radial-gradient(
      55.84% 55.84% at 37.65% 25.18%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(
      133deg,
      rgba(255, 255, 255, 0.23) 14.64%,
      rgba(255, 255, 255, 0) 85.8%
    ),
    var(--Flow-Blue, #34b2ff);
  filter: drop-shadow(50px 100px 100px rgba(0, 0, 0, 0.3));
}

.custom-tabs {
  width: 100%;
}

.tab-buttons {
  display: flex;
  justify-content: space-between;
}

.tab-button {
  border: none;
  background: none;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
}

.tab-button.active {
  border-bottom: 2px solid #007bff;
  color: #fff;
}

.tab-content {
  padding: 20px 0;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  outline: none;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.CloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}
